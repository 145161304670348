import Google from './google';
import FbPixel from './facebook';
import UET from './microsoftAds';

export const initTrackers = () => {
  FbPixel.init();
  UET.init();
  return Google.init();
};

export const trigPageview = location => {
  FbPixel.pageview();
  UET.pageview(location);
  return Google.pageview();
};